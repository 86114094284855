import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cart: {
            lineItems: []
        },
        cartLoaded: false,
        algoliaView: 'grid-view',
        modals: [],
        customFilters: [],
        initFiltersSet: [],
        algoliaUserToken: null
    },
    
    actions: {
        async loadCart({ commit }) {
            return await window.axios({
                url: '/actions/commerce/cart/get-cart',
                method: 'GET'
            }).then(result => {
                if (result.status === 200 && result?.data?.cart) {
                    
                    // Update cart count to show in navigation
                    commit("LOAD_CART", result.data.cart);
                    commit("SET_CART_LOADED");
                    
                    return { success: true, data: result.data.cart, lineItems: result.data?.cart?.lineItems };
                } else {
                    console.error('Could not retrieve cart.', result);
                    return { success: false, data: result };
                }
            }).catch(error => {
                console.error('Could not connect to controller.', error);
                return { success: false, data: error };
            });
        },
        
        saveCart({ commit }, cart) {
            commit('SAVE_CART', cart);
        },
        
        setAlgoliaView({ commit }, view) {
            commit('SET_ALGOLIA_VIEW', view);
        },
    
        toggleModal({ commit }, payload) {
            commit('TOGGLE_MODAL', payload);
        },
        
        saveCustomFilters({ commit }, payload) {
            commit('SET_CUSTOM_FILTERS', payload)
        },
    
        toggleInitFiltersSet({ commit }, uid) {
            commit('SET_INIT_FILTERS_SET', uid)
        }
    },
    
    mutations: {
        SET_ALGOLIA_USER_TOKEN(state, token) {
            state.algoliaUserToken = token;
        },
        
        SET_CART_LOADED(state) {
            state.cartLoaded = true
        },
        
        LOAD_CART (state, payload) {
            state.cart = payload
        },
        
        SAVE_CART (state, cart) {
            state.cart = cart;
        },
        
        SET_ALGOLIA_VIEW (state, view) {
            state.algoliaView = view;
        },
    
        TOGGLE_MODAL (state, payload) {
            if (payload.name) {
                const index = state.modals.findIndex(modal => modal.name === payload.name)
                if (index > -1) {
                    state.modals.splice(index, 1)
                } else {
                    state.modals.push({
                        name: payload.name,
                        data: payload.data
                    })
                }
            }
        },
    
        SET_CUSTOM_FILTERS (state, payload) {
            state.customFilters = payload
        },
    
        SET_INIT_FILTERS_SET (state, uid) {
            const exists = state.initFiltersSet.includes(uid)
            if (exists) {
                state.initFiltersSet.splice(state.initFiltersSet.findIndex(id => id === uid), 1)
            } else {
                state.initFiltersSet.push(uid)
            }
            
        }
    },
    
    getters: {
        getLineItem: state => id => {
            const item = state.cart.lineItems.find(i => i.id === id);
            if (item) {
                return { qty: item.qty, subTotal: item.subtotal };
            } else {
                return { qty: 1, subTotal: null };
            }
        },
        
        showModal: state => id => {
            return state.modals.findIndex(modal => modal.name === id) > -1
        },
        
        getModalData: state => id => {
            const modalData = state.modals.find(modal => modal.name === id)
            return modalData ? modalData.data : null
        },
        
        modalZDepth: state => id => {
            const nameIndex = state.modals.findIndex(modal => modal.name === id)
            if (nameIndex > -1) return nameIndex
            return 0
        },
    
        getInitFiltersSet: state => uid => {
            return state.initFiltersSet.includes(uid)
        },
        
        getLineItems(state) {
            return state.cart.lineItems
        },
        
        getCartTotal(state) {
            return state.cart.total
        }
    },
    
    plugins: [
        createPersistedState({
            paths: [
                "algoliaView",
                "customFilters",
                "initFiltersSet"
            ]
        })
    ]
})
